.layout {
  background-image: url('/public/svg-bg.svg'); /*radial-gradient(50% 50% at 50% 50%, #2e3192 0%, #f1c531 100%);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.container {
  max-width: 408px;
  margin: auto;
}

.title {
  text-align: center;
  color: #626262;
  font-size: 30px;
  letter-spacing: -0.04em;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.image {
  max-width: 300px;
}
